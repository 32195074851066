import { Injectable } from '@angular/core';

import { PaginationDto } from '../dtos/pagination.dto';
import { Pagination } from '../models/pagination';

import { MapperFromDto } from './mappers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RecordAny = Record<string, any>;

/** Pagination mapper. */
@Injectable({ providedIn: 'root' })
export class PaginationMapper {

	/**
	 * Map pagination from dto.
	 * @param page Dto page.
	 * @param mapper Mapper for the items.
	 * @param mapperForExtraData Mapper for extra data from pagination.
	 * @param extraDataDtoSchema Extra data schema.
	 */
	public fromDto<TDto, TDomain, TExtraDto extends RecordAny, TExtra extends RecordAny>(
		page: PaginationDto<TDto, TExtraDto>,
		mapper: MapperFromDto<TDto, TDomain> | MapperFromDto<TDto, TDomain>['fromDto'],
		mapperForExtraData?: MapperFromDto<TExtraDto, TExtra>['fromDto'],
	): Pagination<TDomain, TExtra> {
		const mapperFn = typeof mapper === 'function' ? mapper : mapper.fromDto.bind(mapper);
		if (page.meta == null) {
			return new Pagination({
				items: page.data.map(mapperFn),
				totalCount: page.data.length,
				hasNext: false,
				hasPrev: false,
			});
		}
		const { pagination, extra } = page.meta;
		return new Pagination({
			items: page.data.map(mapperFn),
			totalCount: pagination.total,
			hasNext: pagination.links.next != null,
			hasPrev: pagination.links.prev != null,
			extraData: mapperForExtraData ? mapperForExtraData(extra as TExtraDto) : null,
		});
	}
}
