import { Injectable, inject } from '@angular/core';

import { UserCreateDto, UserDto } from '../dtos/user.dto';
import { User, UserCreate } from '../models/user';

import { DateMapper } from './date.mapper';
import { MapperFromDto } from './mappers';
import { UserRoleMapper } from './user-role.mapper';
import { UserStatusMapper } from './user-status.mapper';

/** User mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserMapper implements MapperFromDto<UserDto, User> {

	private readonly dateMapper = inject(DateMapper);

	private readonly userRoleMapper = inject(UserRoleMapper);

	private readonly userStatusMapper = inject(UserStatusMapper);

	/** @inheritdoc */
	public fromDto(dto: UserDto): User {
		return {
			id: dto.id,
			firstName: dto.first_name,
			lastName: dto.last_name,
			fullName: User.getFullName(dto.first_name, dto.last_name),
			companyId: dto.company_id,
			companyName: dto.company_name,
			email: dto.email,
			emailVerifiedAt: dto.email_verified_at ? this.dateMapper.fromDto(dto.email_verified_at) : null,
			lastSignedIn: dto.last_signed_in ? this.dateMapper.fromDto(dto.last_signed_in) : null,
			rememberToken: dto.remember_token ?? null,
			role: this.userRoleMapper.fromDto(dto.role),
			createdAt: this.dateMapper.fromDto(dto.created_at),
			updateAt: this.dateMapper.fromDto(dto.updated_at),
			status: this.userStatusMapper.fromDto(dto.status),
			phone: dto.phone,
		};
	}

	/** @inheritdoc */
	public toCreateDto(model: UserCreate): UserCreateDto {
		return {
			company_id: model.companyId,
			first_name: model.firstName,
			last_name: model.lastName,
			role: this.userRoleMapper.toDto(model.role),
			email: model.email,
			phone: model.phone,
			add_job_ids: model.includedJobIds,
			remove_job_ids: model.excludedJobIds,
		};
	}
}
