import { Injectable } from '@angular/core';

import { Login2FaDto } from '../dtos/login-2fa.dto';
import { Login2Fa } from '../models/login-2fa';

import { ValidationErrorDto } from '../dtos/validation-error.dto';
import { EntityValidationErrors } from '../models/app-error';

import { extractErrorMessageByField } from './extract-error-message';
import { MapperToDto, ValidationErrorMapper } from './mappers';

/** Login with 2FA data mapper. */
@Injectable({
	providedIn: 'root',
})
export class Login2FaDataMapper implements MapperToDto<Login2FaDto, Login2Fa>, ValidationErrorMapper<Login2FaDto, Login2Fa> {
	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<Login2FaDto>): EntityValidationErrors<Login2Fa> {
		return {
			sessionCode: extractErrorMessageByField(errorDto, 'session_code'),
			twoFactorsCode: extractErrorMessageByField(errorDto, 'two_factor_code'),
		};
	}

	/** @inheritdoc */
	public toDto(model: Login2Fa): Login2FaDto {
		return {
			session_code: model.sessionCode,
			two_factor_code: model.twoFactorsCode,
		};
	}
}
