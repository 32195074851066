import { z } from 'zod';

import { StrictExtract } from '../utils/types/strict-extract';

import { UserRole } from './user-role';
import { UserStatus } from './user-status';
import { quoteJobSchema } from './quote-job';

/** User schema. */
export const userSchema = z.object({
	id: z.number(),
	firstName: z.string(),
	lastName: z.string(),
	fullName: z.string(),
	companyName: z.string().nullable(),
	companyId: z.number().nullable(),
	email: z.string(),
	emailVerifiedAt: z.date().nullable(),
	lastSignedIn: z.date().nullable(),
	rememberToken: z.string().nullable(),
	role: z.nativeEnum(UserRole),
	status: z.nativeEnum(UserStatus),
	createdAt: z.date(),
	updateAt: z.date(),
	phone: z.string().nullable(),
});

/** User create schema. */
const userCreateSchema = userSchema.pick({
	companyId: true,
	firstName: true,
	lastName: true,
	role: true,
	email: true,
	phone: true,
}).extend({
	includedJobIds: z.array(quoteJobSchema.shape.id),
	excludedJobIds: z.array(quoteJobSchema.shape.id),
});

/** Basic representation of a user. */
export type User = Readonly<z.infer<typeof userSchema>>;

/** User create model. */
export type UserCreate = Readonly<z.infer<typeof userCreateSchema>>;

export namespace User {

	/**
	 * Get user full name.
	 * @param firstName First name.
	 * @param lastName Last name.
	 */
	export function getFullName(firstName: string, lastName: string): string {
		return `${firstName} ${lastName}`;
	}
}

/** User sort fields. */
export type UserSortField = StrictExtract<keyof User, 'email' | 'role' | 'status' | 'lastSignedIn' | 'id' | 'companyName'> | 'fullName';
