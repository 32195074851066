
/** Required 2FA Validation code. */
export const ERROR_TYPES = {
	required2Fa: 'required2FA',
	validation: 'validation',
} as const;

/**
 * Entity validation errors type.
 * Describes validation items for target entity.
 */
export type EntityValidationErrors<T> = {

	/**
	 * Error message for certain entity property.
	 */
	[P in keyof T]?: PropValidationMessage<T[P]> | string | string[];
};

/**
 * Validation message type for specific property type.
 * Could be a just error message for simple field or nested validation error for composite fields.
 */
export type PropValidationMessage<T> = T extends readonly unknown[]
	? string
	: T extends object
		? EntityValidationErrors<T>
		: string;

/**
 * An error that was raised by the application intentionally.
 */
export class AppError extends Error {
	/**
	 * Error message.
	 */
	public override readonly message: string;

	/** Error type. */
	public readonly type?: unknown;

	public constructor(message: string, type?: unknown) {
		super(message);
		this.message = message;
		this.type = type;
	}
}

/**
 * An error that was raised by the application when 2FA validation is required, with session code.
 */
export class AppErrorRequired2FaValidation extends AppError {

	/** Session code for 2FA validation. */
	public readonly sessionCode: string;

	public constructor(message: string, type: unknown, sessionCode: string) {
		super(message, type);
		this.sessionCode = sessionCode;
	}
}

/**
 * Application validation error for certain Entity.
 */
export class AppValidationError<TEntity extends object> extends AppError {
	/**
	 * Validation errors for entity fields.
	 */
	public readonly validationData: EntityValidationErrors<TEntity>;

	public constructor(
		message: string,
		validationData: EntityValidationErrors<TEntity>,
	) {
		super(message, ERROR_TYPES.validation);
		this.validationData = validationData;
	}
}
