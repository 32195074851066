import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { userSecretDtoSchema } from '../dtos/user-secret.dto';
import { AppErrorMapper } from '../mappers/app-error.mapper';
import { Login2FaDataMapper } from '../mappers/login-2fa-data.mapper';
import { LoginDataMapper } from '../mappers/login-data.mapper';
import { PasswordChangeMapper } from '../mappers/password-change.mapper';
import { ResetPasswordConfirmationMapper } from '../mappers/reset-password-confirmation.mapper';
import { ResetPasswordMapper } from '../mappers/reset-password.mapper';
import { UserSecretDataMapper } from '../mappers/user-secret-data.mapper';
import { Login } from '../models/login';
import { Login2Fa } from '../models/login-2fa';
import { PasswordReset } from '../models/password-reset';
import { UserSecret } from '../models/user-secret';
import { safeParse } from '../utils/safe-parse';

import { AppUrlsConfig } from './app-urls.config';

/**
 * Performs CRUD operations for auth-related information.
 */
@Injectable({ providedIn: 'root' })
export class AuthApiService {

	private readonly httpClient = inject(HttpClient);

	private readonly apiUrlsConfig = inject(AppUrlsConfig);

	private readonly loginDataMapper = inject(LoginDataMapper);

	private readonly login2FaDataMapper = inject(Login2FaDataMapper);

	private readonly appErrorMapper = inject(AppErrorMapper);

	private readonly userSecretMapper = inject(UserSecretDataMapper);

	private readonly resetPasswordMapper = inject(ResetPasswordMapper);

	private readonly resetPasswordConfirmationMapper = inject(ResetPasswordConfirmationMapper);

	private readonly passwordChangeMapper = inject(PasswordChangeMapper);

	/**
	 * Login a user with email and password.
	 * @param loginData Login data.
	 */
	public login(loginData: Login): Observable<UserSecret> {
		return this.httpClient.post<unknown>(
			this.apiUrlsConfig.auth.login,
			this.loginDataMapper.toDto(loginData),
		)
			.pipe(
				map(response => safeParse(userSecretDtoSchema, response)),
				map(secretDto => this.userSecretMapper.fromDto(secretDto)),
				this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
					this.loginDataMapper,
				),
			);
	}

	/**
	 * Login a user with 2 factors validation data.
	 * @param login2FaData Login data.
	 */
	public loginWith2FaCode(login2FaData: Login2Fa): Observable<UserSecret> {
		return this.httpClient.post<unknown>(
			this.apiUrlsConfig.auth.login2Fa,
			this.login2FaDataMapper.toDto(login2FaData),
		)
			.pipe(
				map(response => safeParse(userSecretDtoSchema, response)),
				map(secretDto => this.userSecretMapper.fromDto(secretDto)),
				this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
					this.login2FaDataMapper,
				),
			);
	}

	/**
	 * Refresh user's secret.
	 * @param secret Secret data.
	 */
	public refreshSecret(
		secret: UserSecret,
	): Observable<UserSecret> {
		return this.httpClient.post<unknown>(
			this.apiUrlsConfig.auth.refreshSecret,
			this.userSecretMapper.toDto(secret),
		)
			.pipe(
				map(response => safeParse(userSecretDtoSchema, response)),
				map(secretDto => this.userSecretMapper.fromDto(secretDto)),
				this.appErrorMapper.catchHttpErrorToAppError(),
			);
	}

	/**
	 * Sends request to reset the password.
	 * @param data Data for password reset.
	 * @returns Success message.
	 */
	public resetPassword(data: PasswordReset.Data): Observable<void> {
		return this.httpClient.post<unknown>(
			this.apiUrlsConfig.auth.forgotPassword,
			this.resetPasswordMapper.toDto(data),
		)
			.pipe(
				map(() => undefined),
				this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
					this.resetPasswordMapper,
					'User with such email doesn\'t exist.',
				),
			);
	}

	/**
	 * Confirms password reset and applies new passwords to the account.
	 * @param data New passwords data.
	 * @returns Success message.
	 */
	public confirmPasswordReset(
		data: PasswordReset.Confirmation,
	): Observable<void> {
		return this.httpClient.post<unknown>(
			this.apiUrlsConfig.auth.resetPassword,
			this.resetPasswordConfirmationMapper.toDto(data),
		)
			.pipe(
				map(() => undefined),
				this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
					this.resetPasswordConfirmationMapper,
				),
			);
	}

	/**
	 * Check whether password reset token is valid.
	 * @param token Password reset token.
	 * @returns If token is valid empty body and 204 status,
	 * else validation error and 422 status.
	 */
	public checkPasswordResetTokenValidity(
		token: string,
	): Observable<unknown> {
		return this.httpClient.post<unknown>(
			this.apiUrlsConfig.auth.checkResetPassword,
			{ token },
		)
			.pipe(
				map(() => undefined),
				this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
					this.resetPasswordConfirmationMapper,
				),
			);
	}
}
