import { StrictOmit } from '../utils/types/strict-omit';

/** Pagination extra meta data. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PaginationExtraMetaData = Record<string, any>;

type PaginationConstructorData<T, TExtra extends PaginationExtraMetaData> = StrictOmit<Pagination<T, TExtra>, 'hasItems'>;

/** Pagination wrapper. */
export class Pagination<T, TExtra extends PaginationExtraMetaData = PaginationExtraMetaData> {

	/** Items on the page. */
	public readonly items: readonly T[];

	/** Extra data. */
	public readonly extraData?: TExtra | null;

	/** Total count in the store. */
	public readonly totalCount: number;

	/** Whether the pagination have next pages. */
	public readonly hasNext: boolean;

	/** Whether the pagination have prev pages. */
	public readonly hasPrev: boolean;

	public constructor(
		data: PaginationConstructorData<T, TExtra>,
	) {
		this.items = data.items;
		this.totalCount = data.totalCount;
		this.hasNext = data.hasNext;
		this.hasPrev = data.hasPrev;
		this.extraData = data.extraData;
	}

	/** Whether items is present or not.  */
	public get hasItems(): boolean {
		return this.items.length > 0;
	}
}
