import type { ZodError } from 'zod';

/**
 * Formats Zod error into a compact readable string.
 * @param error ZodError.
 * @example
 * data.1.mei_facility: Expected string, received null
 * data.1.manufacturer: Expected string, received null
 */
export function formatZodError(error: ZodError): string {
	return error.issues.map(issue => `${issue.path.join('.')}: ${issue.message}`).join('\n');
}
