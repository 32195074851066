import { Injectable, inject } from '@angular/core';

import { BaseFilterParamsDto } from '../dtos/base-filter-params.dto';
import { BaseFilterParams } from '../models/base-filter-params';

import { SortMapper } from './sort.mapper';

/** Mapper for filter params. */
@Injectable({ providedIn: 'root' })
export class BaseFilterParamsMapper {

	private readonly sortMapper = inject(SortMapper);

	/** @inheritdoc */
	public mapPaginationOptionsToDto(model: BaseFilterParams.Pagination): BaseFilterParamsDto.Pagination {
		return {
			page: model.pageNumber + 1,
			per_page: model.pageSize,
		};
	}

	/** @inheritdoc */
	public mapSearchOptionsToDto(model: BaseFilterParams.Search): BaseFilterParamsDto.Search {
		return {
			keyword: model.search,
		};
	}

	/** @inheritdoc */
	public mapCombinedOptionsToDto(model: BaseFilterParams.Combined, sortFieldMap: Record<string, unknown>): BaseFilterParamsDto.Combined {
		return {
			...this.mapPaginationOptionsToDto(model),
			...this.mapSearchOptionsToDto(model),
			sort: model.sortOptions?.direction && model.sortOptions.field ?
				this.sortMapper.toDto(model.sortOptions, sortFieldMap) :
				undefined,
		};
	}

}
