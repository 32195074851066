import { z } from 'zod';

import { StrictExclude } from '../utils/types/strict-exclude';

/** Quote job DTO schema. */
export const quoteJobDtoSchema = z.object({
	job_id: z.string(),
	id: z.number(),
	job_name: z.string(),
	job_address: z.string(),
	city: z.string(),
	state: z.string(),
	last_activity_date: z.string(),
	company: z.string(),
	company_id: z.number(),

	// TODO (Sotnichenko A): Remove nullable() when API is fixed.
	shipments_received: z.number().nullable(),
	items_in_storage: z.number().nullable(),
	is_completed: z.boolean(),
	completed_on: z.string().nullable(),
});

/** User job extra meta data DTO schema. */
export const userJobExtraMetaDataDtoSchema = z.object({
	company_jobs: z.number(),
	other_jobs: z.number(),
});

/** Quote job dto data. */
export type QuoteJobDto = Readonly<z.infer<typeof quoteJobDtoSchema>>;

/** Job available sort fields. */
export type QuoteJobSortFieldDto = StrictExclude<keyof QuoteJobDto, 'completed_on' | 'id' | 'company'> | 'company_name';

/** User job extra meta data DTO. */
export type UserJobExtraMetaDataDto = Readonly<z.infer<typeof userJobExtraMetaDataDtoSchema>>;
