import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../models/app-error';
import { PasswordReset } from '../models/password-reset';
import { PasswordResetDto } from '../dtos/password-reset.dto';
import { ValidationErrorDto } from '../dtos/validation-error.dto';

import { extractErrorMessageByField } from './extract-error-message';
import { MapperToDto, ValidationErrorMapper } from './mappers';

/** Mapper for reset password data. */
@Injectable({ providedIn: 'root' })
export class ResetPasswordConfirmationMapper
implements
    MapperToDto<PasswordResetDto.Confirmation, PasswordReset.Confirmation>,
    ValidationErrorMapper<PasswordResetDto.Confirmation, PasswordReset.Confirmation> {
	/** @inheritdoc */
	public toDto(model: PasswordReset.Confirmation): PasswordResetDto.Confirmation {
		return {
			password: model.password,
			token: model.token,
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<PasswordResetDto.Confirmation>,
	): EntityValidationErrors<PasswordReset.Confirmation> {
		return {
			password: extractErrorMessageByField(errorDto, 'password'),
		};
	}
}
