import { HttpErrorResponse } from '@angular/common/http';

import { MaxErrorDepth, ObjectPaths } from '../utils/types/object-paths';

/** Error returned by API. */
export type ApiError<T> = {

	/** Error description.  */
	readonly detail: string;

	/** Key of error. */
	readonly field: ObjectPaths<T, MaxErrorDepth>;
};

/** Validation error DTO. */
export type ValidationErrorDto<T> = {

	/** Error type. */
	readonly type: string;

	/** Detail of error. */
	readonly detail: string;

	/** Errors. */
	readonly errors: readonly ApiError<T>[];
};

/**
 * Is error a ValidationErrorDto.
 * @param error Some error.
 */
export function isValidationErrorDto<T>(error: HttpErrorResponse['error']): error is ValidationErrorDto<T> {
	return (error as ValidationErrorDto<T>).errors != null;
}
