import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

import { DEFAULT_DATE_FORMAT } from '../constants/date-format';

import { Mapper } from './mappers';

/** Date mapper. */
@Injectable({
	providedIn: 'root',
})
export class DateMapper implements Mapper<string, Date> {

	/** @inheritdoc */
	public fromDto(data: string, customFormat: string = DEFAULT_DATE_FORMAT): Date {
		const parsedDateFromISOString = DateTime.fromISO(data);
		if (parsedDateFromISOString.isValid) {
			return parsedDateFromISOString.toJSDate();
		}
		return DateTime.fromFormat(data, customFormat).toJSDate();
	}

	/** @inheritdoc */
	public toDto(data: Date, customFormat = 'yyyy-MM-dd'): string {
		return DateTime.fromJSDate(data).toFormat(customFormat);
	}
}
