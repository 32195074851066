import { Injectable } from '@angular/core';

import { reverseRecord } from '../utils/reverse-record';
import { UserStatusDto } from '../dtos/user-status.dto';
import { UserStatus } from '../models/user-status';

import { Mapper } from './mappers';

const TO_USER_STATUS_DTO: Readonly<Record<UserStatus, UserStatusDto>> = {
	[UserStatus.Active]: UserStatusDto.Active,
	[UserStatus.Deactivated]: UserStatusDto.Deactivated,
	[UserStatus.Inactive]: UserStatusDto.Inactive,
};

const FROM_USER_STATUS_DTO = reverseRecord(TO_USER_STATUS_DTO);

/** User status mapper. */
@Injectable({ providedIn: 'root' })
export class UserStatusMapper implements Mapper<UserStatusDto, UserStatus> {

	/** @inheritdoc */
	public fromDto(data: UserStatusDto): UserStatus {
		return FROM_USER_STATUS_DTO[data];
	}

	/** @inheritdoc */
	public toDto(data: UserStatus): UserStatusDto {
		return TO_USER_STATUS_DTO[data];
	}
}
