import { Injectable, inject } from '@angular/core';

import { QuoteJob } from '../models/quote-job';

import { QuoteJobDto } from '../dtos/quote-job.dto';

import { MapperFromDto } from './mappers';
import { DateMapper } from './date.mapper';

/** Quote job mapper. */
@Injectable({
	providedIn: 'root',
})
export class QuoteJobMapper implements MapperFromDto<QuoteJobDto, QuoteJob> {
	private readonly dateMapper = inject(DateMapper);

	/** @inheritdoc */
	public fromDto(dto: QuoteJobDto): QuoteJob {
		return {
			id: dto.id,
			jobId: dto.job_id,
			jobName: dto.job_name,
			jobAddress: dto.job_address,
			city: dto.city,
			state: dto.state,
			lastActivityDate: this.dateMapper.fromDto(dto.last_activity_date),
			companyId: dto.company_id,
			companyName: dto.company,

			// TODO (Sotnichenko A): Remove `?? 0` when API is fixed.
			shipmentsReceived: dto.shipments_received ?? 0,
			itemsInStorage: dto.items_in_storage ?? 0,
			isCompleted: dto.is_completed,
			completedOn: dto.completed_on ? this.dateMapper.fromDto(dto.completed_on) : null,
		};
	}
}
