import { Injectable, inject } from '@angular/core';

import { UserStatusFilterParamsDto } from '../dtos/user-status-filter-params.dto';
import { UserStatusFilterParams } from '../models/user-status-filter-params';

import { MapperToDto } from './mappers';
import { UserStatusMapper } from './user-status.mapper';

/** User status filter params mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserStatusFilterParamsMapper implements MapperToDto<UserStatusFilterParamsDto, UserStatusFilterParams> {
	private readonly userStatusMapper = inject(UserStatusMapper);

	/** @inheritdoc */
	public toDto(data: UserStatusFilterParams): UserStatusFilterParamsDto {
		return {
			status: this.userStatusMapper.toDto(data.status) ?? undefined,
		};
	}
}
