import { Injectable, inject } from '@angular/core';

import { AppConfig } from './app.config';

/**
 * Urls used within the application.
 * Stringified for convenience, since most of the Angular's HTTP tools work with strings.
 */
@Injectable({ providedIn: 'root' })
export class AppUrlsConfig {

	private readonly appConfigService = inject(AppConfig);

	/** Auth-related routes. */
	public readonly auth = {
		login: this.toApi('login'),
		login2Fa: this.toApi('login-code'),
		refreshSecret: this.toApi('auth/refresh-token/'),
		forgotPassword: this.toApi('forgot-password/'),
		resetPassword: this.toApi('reset-password/'),
		checkResetPassword: this.toApi('check-reset-password/'),
	} as const;

	/** Routes for getting/editing current user's info. */
	public readonly user = {
		currentProfile: this.toApi('me'),
		list: this.toApi('users'),
		create: this.toApi('users'),
		update: (userId: number) => this.toApi('users', userId.toString()),
		detail: (userId: number) => this.toApi('users', userId.toString()),
		delete: (userId: number) => this.toApi('users', userId.toString()),
		changeStatus: (userId: number) => this.toApi('users', userId.toString(), 'status'),
		jobs: (userId: number) => this.toApi('users', userId.toString(), 'jobs'),
	} as const;

	/** Routes for jobs. */
	public readonly jobs = {
		list: this.toApi('jobs'),
		detail: (jobId: number) => this.toApi('jobs', jobId.toString()),
		shipments: (jobId: number) => this.toApi('jobs', jobId.toString(), 'shipments'),
		items: (jobId: number) => this.toApi('jobs', jobId.toString(), 'items'),
	} as const;

	/** Routes for shipments. */
	public readonly shipments = {
		list: this.toApi('shipments'),
		items: this.toApi('shipment-items'),
		detail: (shipmentId: number) => this.toApi('shipments', shipmentId.toString()),
		itemsByShipment: (shipmentId: number) => this.toApi('shipments', shipmentId.toString(), 'items'),
		itemDetail: (itemId: number) => this.toApi('shipments', 'items', itemId.toString()),
		files: (shipmentId: number) => this.toApi('shipments', shipmentId.toString(), 'files'),
		itemFiles: (itemId: number) => this.toApi('shipments', 'items', itemId.toString(), 'files'),
		tags: this.toApi('shipments', 'files', 'tags'),
		downloadFile: (fileId: number) => this.toApi('shipments', 'files', fileId.toString(), 'download'),
	} as const;

	/** Routes for reports. */
	public readonly reports = {
		monthlyReport: this.toApi('reports', 'monthly'),
		createCustomReport: this.toApi('reports', 'custom'),
		getCustomReport: this.toApi('reports', 'custom'),
		itemsStatistic: this.toApi('reports', 'items', 'options'),
		downloadItemsReport: this.toApi('reports', 'items', 'options', 'download'),
		downloadCustomReport: (reportId: number) => this.toApi('reports', 'custom', reportId.toString(), 'download'),
		downloadAllCustomReports: this.toApi('reports', 'download', 'custom-reports'),
		downloadAllMonthlyReports: this.toApi('reports', 'download', 'monthly-reports'),
		downloadShipmentsReport: this.toApi('reports', 'shipments'),
	} as const;

	/** Routes for locations. */
	public readonly locations = {
		list: this.toApi('locations'),
	} as const;

	/** Routes for customers. */
	public readonly customers = {
		list: this.toApi('customers'),
	} as const;

	/** Routes for report. */
	public readonly report = {
		summary: this.toApi('summary'),
	} as const;

	/**
	 * Checks whether the url is application-scoped.
	 * @param url Url to check.
	 */
	public isApplicationUrl(url: string): boolean {
		return url.startsWith(this.appConfigService.apiUrl);
	}

	/**
	 * Checks whether the specified url is calling an auth-related endpoint.
	 * @param url Url to check.
	 */
	public isAuthUrl(url: string): boolean {
		return Object.values(this.auth).find(authUrl => authUrl.includes(url)) != null;
	}

	private toApi(...args: readonly string[]): string {
		const path = args.join('/');
		return new URL(path, this.appConfigService.apiUrl).toString();
	}
}
