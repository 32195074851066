import { Injectable, inject } from '@angular/core';

import { UsersFilterParams } from '../models/users-filter-params';
import { UserFilterParamsDto } from '../dtos/user-filter-params.dto';
import { UserSortField } from '../models/user';
import { UserSortFieldDto } from '../dtos/user.dto';

import { BaseFilterParamsMapper } from './base-filter-params.mapper';
import { MapperToDto } from './mappers';
import { UserRoleMapper } from './user-role.mapper';
import { UserStatusMapper } from './user-status.mapper';

const USERS_SORT_FIELD_MAP: Readonly<Record<UserSortField, UserSortFieldDto>> = {
	id: 'id',
	fullName: 'full_name',
	lastSignedIn: 'last_signed_in',
	role: 'role',
	status: 'status',
	email: 'email',
	companyName: 'company_name',
};

/** User filter params mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserFilterParamsMapper implements MapperToDto<UserFilterParamsDto, UsersFilterParams> {

	private readonly baseFilterParamsMapper = inject(BaseFilterParamsMapper);

	private readonly userRoleMapper = inject(UserRoleMapper);

	private readonly userStatusMapper = inject(UserStatusMapper);

	/** @inheritdoc */
	public toDto(data: UsersFilterParams): UserFilterParamsDto {
		return {
			...this.baseFilterParamsMapper.mapCombinedOptionsToDto(data, USERS_SORT_FIELD_MAP),
			status: data.status ? this.userStatusMapper.toDto(data.status) : undefined,
			role: data.role ? this.userRoleMapper.toDto(data.role) : undefined,
			q: data.search ?? undefined,
		};
	}
}
