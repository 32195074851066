import { z } from 'zod';

import { userDtoSchema } from './user.dto';

/** User details DTO. */
export const userDetailsSchemaDto = userDtoSchema.extend({
	permission_job_ids: z.array(z.number()).optional(),
});

/** User details DTO. */
export type UserDetailsDto = Readonly<z.infer<typeof userDetailsSchemaDto>>;
