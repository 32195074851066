import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction } from 'rxjs';

import { AppError, AppErrorRequired2FaValidation, AppValidationError, ERROR_TYPES } from '../models/app-error';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';

import { isValidationErrorDto } from '../dtos/validation-error.dto';

import { ValidationErrorMapper } from './mappers';

/**
 * Could be a simple function that transform errors from DTO to domain-level errors
 * or an implementation of `IMapper` with implemented `validationErrorFromDto` method.
 */
export type ErrorMapper<TDto, TEntity extends Record<string, unknown>> =
  | ValidationErrorMapper<TDto, TEntity>
  | ValidationErrorMapper<TDto, TEntity>['validationErrorFromDto'];

/**
 * Errors mapper.
 */
@Injectable({ providedIn: 'root' })
export class AppErrorMapper {

	/**
	 * Check if the error DTO is 2FA required error or not.
	 * @param error Error to check. We don't know what is the error type from BE, so we use `any` as type here.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private is2FaRequiredError(error: any): boolean {
		return error?.session_code != null;
	}

	/**
	 * Maps `HttpErrorResponse` to an application-level error.
	 * @param httpError Http error response.
	 */
	private fromDto(httpError: HttpErrorResponse): AppError {
		const { statusText, error } = httpError;
		if (this.is2FaRequiredError(error)) {
			return new AppErrorRequired2FaValidation(error?.detail ?? statusText, ERROR_TYPES.required2Fa, error?.session_code);
		}
		return new AppError(error?.detail ?? statusText, error?.type);
	}

	/**
	 * Maps `HttpErrorResponse` to either `AppError` or `AppValidationError`.
	 * @param httpError Http error.
	 * @param mapper Mapper for backend-provided validation data into domain validation data.
	 */
	private fromDtoWithValidationSupport<TDto, TEntity extends Record<string, unknown>>(
		httpError: HttpErrorResponse,
		mapper: ErrorMapper<TDto, TEntity>,
	): AppError | AppValidationError<TEntity> {
		const validationDataCodes = [HttpStatusCode.BadRequest, HttpStatusCode.UnprocessableEntity];
		if (!validationDataCodes.includes(httpError.status)) {
			return this.fromDto(httpError);
		}

		const { error, statusText } = httpError;
		if (error == null || !isValidationErrorDto<TDto>(error)) {
			return this.fromDto(httpError);
		}

		const validationData =
			typeof mapper === 'function' ?
				mapper(error) :
				mapper.validationErrorFromDto(error);

		return new AppValidationError<TEntity>(statusText, validationData);
	}

	/**
	 * RxJS operator that catches `HttpErrorResponse` and maps it into application error.
	 */
	public catchHttpErrorToAppError<T>(): MonoTypeOperatorFunction<T> {
		return catchHttpErrorResponse(error => {
			throw this.fromDto(error);
		});
	}

	/**
	 * RxJS operator that catches `HttpErrorResponse` and maps it into application error that may contain validation data.
	 * @param mapper Mapper for backend-provided validation data into domain validation data.
	 * @param notFoundErrorMessage Not found error message.
	 */
	public catchHttpErrorToAppErrorWithValidationSupport<
		T,
		TDto,
		TEntity extends Record<string, unknown>,
	>(
		mapper: ErrorMapper<TDto, TEntity>,
		notFoundErrorMessage?: string,
	): MonoTypeOperatorFunction<T> {
		return catchHttpErrorResponse(error => {
			if (error.status === 404 && notFoundErrorMessage != null) {
				throw new AppError(notFoundErrorMessage);
			}
			throw this.fromDtoWithValidationSupport<TDto, TEntity>(
				error,
				mapper,
			);
		});
	}
}
