/** User status. */
export enum UserStatus {
	Active = 'Active',
	Inactive = 'Inactive',
	Deactivated = 'DeActivated',
}

const USER_STATUS_TO_READABLE: Record<UserStatus, string> = {
	[UserStatus.Active]: 'Active',
	[UserStatus.Inactive]: 'Inactive',
	[UserStatus.Deactivated]: 'De-activated',
};

export namespace UserStatusMethods {

	/**
	 * To readable user status.
	 * @param item User status.
	 */
	export function toReadable(item: UserStatus): string {
		return USER_STATUS_TO_READABLE[item];
	}
}
