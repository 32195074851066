import { inject, InjectionToken, Provider } from '@angular/core';
import { buildRoutePaths } from '@mei/common/core/utils/route-paths/build-route-paths';
import { commonRoutePaths } from '@mei/common/core/utils/route-paths/common-route-paths';

/** Job ID param. */
export const JOB_ID_PARAM = 'jobId';

/** Shipment ID param. */
export const SHIPMENT_ID_PARAM = 'shipmentId';

/** Shipment item ID param. */
export const SHIPMENT_ITEM_ID_PARAM = 'shipmentItemId';

/** User ID param. */
export const USER_ID_PARAM = 'userId';

/** Injection token that provide object with route web app paths. */
const WEB_ROUTE_PATHS_TOKEN = new InjectionToken<WebRoutePaths>('Provide object with web route paths');

/**
 * Web route paths object.
 * It's intended to be used only in Routing modules.
 * So don't import this object directly into components.
 * Prefer to use `injectWebAppRoutes` instead.
 * It's necessary to make our component more flexible for unit tests.
 * @example
 * ```ts
 * const routes: Routes = [
 *   { path: webRoutePaths.home, component: HomePageComponent },
 *   // ...
 * ];
 * ```
 */
export const webRoutePaths = buildRoutePaths({
	...commonRoutePaths,
	customerStorage: {
		path: 'customer-storage',
		children: {
			dashboard: { path: 'dashboard' },
			jobs: {
				path: 'jobs',
				children: {
					details:	{
						path: `:${JOB_ID_PARAM}`,
						children: {
							shipments: {
								path: 'shipments',
								children: {
									details:	{
										path: `:${SHIPMENT_ID_PARAM}`,
										children: {
											items: {
												path: 'items',
												children: {
													details:	{ path: `:${SHIPMENT_ITEM_ID_PARAM}` },
												},
											},
										},
									},

								},
							},
							items: {
								path: 'items',
								children: {
									details:	{ path: `:${SHIPMENT_ITEM_ID_PARAM}` },
								},
							},
						},
					},
				},
			},
			users: {
				path: 'users',
				children: {
					create:	{ path: `create` },
					profile:	{ path: `:${USER_ID_PARAM}` },
					edit: { path: `edit/:${USER_ID_PARAM}` },
				},
			},
			shipments: {
				path: 'shipments',
				children: {
					details:	{
						path: `:${SHIPMENT_ID_PARAM}`,
						children: {
							items: {
								path: 'items',
								children: {
									details:	{ path: `:${SHIPMENT_ITEM_ID_PARAM}` },
								},
							},
						},
					},

				},
			},
			items: {
				path: 'items',
				children: {
					details:	{ path: `:${SHIPMENT_ITEM_ID_PARAM}` },
				},
			},
			reports: { path: 'reports' },
			resources: { path: 'resources' },
			customerReport: { path: 'customerReport' },
		},
	},
	auth: {
		path: 'auth',
		children: {
			login: { path: 'login' },
			forgotPassword: { path: 'forgot-password' },
			confirmPassword: { path: 'change-password' },
			register: { path: 'register' },
		},
	},
} as const);

type WebRoutePaths = typeof webRoutePaths;

/** Create provider for a web route paths. */
export function provideWebAppRoutes(): Provider {
	return {
		provide: WEB_ROUTE_PATHS_TOKEN,
		useValue: webRoutePaths,
	};
}

/**
 * Inject web app route paths to component.
 * Warning: Method should be called in the constructor phase to avoid runtime error because of `inject()`.
 * @example
 * ```ts
 * class SomeComponent {
 *   // ...
 *   protected readonly routePaths = injectWebAppRoutes();
 *   public constructor() { };
 * }
 * ```
 */
export function injectWebAppRoutes(): WebRoutePaths {
	return inject(WEB_ROUTE_PATHS_TOKEN);
}
