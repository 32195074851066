import { Injectable } from '@angular/core';

import { UserJobExtraMetaDataDto } from '../dtos/quote-job.dto';
import { UserJobExtraMetaData } from '../models/quote-job';

import { MapperFromDto } from './mappers';

/** User job extra meta data mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserJobExtraMetaDataMapper implements MapperFromDto<UserJobExtraMetaDataDto, UserJobExtraMetaData> {

	/** @inheritdoc */
	public fromDto(dto: UserJobExtraMetaDataDto): UserJobExtraMetaData {
		return {
			companyJobs: dto.company_jobs,
			otherJobs: dto.other_jobs,
		};
	}
}
