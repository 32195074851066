
import { Injectable, inject } from '@angular/core';

import { UserDetailsDto } from '../dtos/user-details.dto';
import { UserDetails } from '../models/user-details';
import { EntityValidationErrors } from '../models/app-error';
import { ValidationErrorDto } from '../dtos/validation-error.dto';

import { MapperFromDto, ValidationErrorMapper } from './mappers';
import { UserMapper } from './user.mapper';
import { extractErrorMessageByField } from './extract-error-message';

/** User details mapper. */
@Injectable({
	providedIn: 'root',
})
export class UserDetailsMapper implements
	MapperFromDto<UserDetailsDto, UserDetails>,
	ValidationErrorMapper<UserDetailsDto, UserDetails> {

	private readonly userMapper = inject(UserMapper);

	/** @inheritdoc */
	public fromDto(dto: UserDetailsDto): UserDetails {
		return {
			...this.userMapper.fromDto(dto),
			permissionJobIds: dto.permission_job_ids ?? [],
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<UserDetailsDto>): EntityValidationErrors<UserDetails> {
		return {
			email: extractErrorMessageByField(errorDto, 'email'),
			firstName: extractErrorMessageByField(errorDto, 'first_name'),
			lastName: extractErrorMessageByField(errorDto, 'last_name'),
			phone: extractErrorMessageByField(errorDto, 'phone'),
			role: extractErrorMessageByField(errorDto, 'role'),
			companyName: extractErrorMessageByField(errorDto, 'company_name'),
		};
	}
}
