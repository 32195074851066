
import { TypeOf, ZodType, z } from 'zod';

const paginationMetaDataDtoSchema = z.object({
	current_page: z.number(),
	last_page: z.number(),
	per_page: z.number(),
	total: z.number(),
	links: z.object({
		first: z.string().nullable(),
		last: z.string().nullable(),
		prev: z.string().nullable(),
		next: z.string().nullable(),
	}),
});

/**
 * Creates pagination DTO schema with extra meta data schema.
 * Sometimes, BE will give us extra data to the pagination response.
 * This parser will be used to handle such case.
 * @param itemSchema Some item schema.
 * @param extraMetaDataSchema Some extra data schema.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function createPaginationDtoWithExtraMetaDataSchema<
	T extends z.ZodTypeAny,
	E extends z.ZodTypeAny,
>(itemSchema: T, extraMetaDataSchema: E) {
	return z.object({
		data: z.array(itemSchema),
		meta: z.object({
			pagination: paginationMetaDataDtoSchema,
			extra: extraMetaDataSchema.optional(),
		}).optional(),
	}).strict();
}

/**
 * Creates pagination DTO schema.
 * @param itemSchema Some item schema.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function createPaginationDtoSchema<T extends z.ZodTypeAny>(itemSchema: T) {
	return z.object({
		data: z.array(itemSchema),
		meta: z.object({ pagination: paginationMetaDataDtoSchema }).optional(),
	}).strict();
}

/** Pagination DTO. */
export type PaginationDto<T, E> = TypeOf<
ReturnType<typeof createPaginationDtoWithExtraMetaDataSchema<ZodType<T>, ZodType<E>>>
>;
