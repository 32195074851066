/** User role. */
export enum UserRole {
	InternalAdmin = 'internalAdmin',
	Internal = 'internal',
	ExternalCompany = 'externalCompany',
	ExternalProject = 'externalProject',
}

const USER_ROLE_TO_READABLE: Record<UserRole, string> = {
	[UserRole.Internal]: 'Internal',
	[UserRole.ExternalCompany]: 'External (Company)',
	[UserRole.ExternalProject]: 'External (Project)',
	[UserRole.InternalAdmin]: 'Internal Admin',
};

export namespace UserRoleMethods {

	/**
	 * To readable user role.
	 * @param item User role.
	 */
	export function toReadable(item: UserRole): string {
		return USER_ROLE_TO_READABLE[item];
	}
}
