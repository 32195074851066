import { z } from 'zod';

import { StrictExtract } from '../utils/types/strict-extract';

import { UserRoleDto } from './user-role.dto';
import { UserStatusDto } from './user-status.dto';
import { quoteJobDtoSchema } from './quote-job.dto';

/** User DTO schema. */
export const userDtoSchema = z.object({
	id: z.number(),
	first_name: z.string(),
	last_name: z.string(),
	company_id: z.number().nullable(),
	company_name: z.string().nullable(),
	email: z.string(),
	email_verified_at: z.string().nullable()
		.optional(),
	last_signed_in: z.string().nullable()
		.optional(),
	remember_token: z.string().nullable()
		.optional(),
	role: z.nativeEnum(UserRoleDto),
	created_at: z.string(),
	updated_at: z.string(),
	status: z.nativeEnum(UserStatusDto),
	phone: z.string().nullable(),
});

/** User create schema. */
const userCreateDtoSchema = userDtoSchema.pick({
	company_id: true,
	first_name: true,
	last_name: true,
	role: true,
	email: true,
	phone: true,
}).extend({
	add_job_ids: z.array(quoteJobDtoSchema.shape.id),
	remove_job_ids: z.array(quoteJobDtoSchema.shape.id),
});

/** User DTO. */
export type UserDto = Readonly<z.infer<typeof userDtoSchema>>;

/** User create DTO. */
export type UserCreateDto = Readonly<z.infer<typeof userCreateDtoSchema>>;

/** User sort field DTO. */
export type UserSortFieldDto =
	| StrictExtract<keyof UserDto, 'id' | 'email' | 'role' | 'status' | 'last_signed_in' | 'company_name'>
	| 'full_name';
